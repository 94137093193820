/*global google*/
import { MARKER_SVG } from './globals';
import { hideSidebar } from '../off_canvas';

/* Get your geolocation */
export function geolocateMe(map) {
  const myElement = document.querySelector('#geolocation');

  // If you are not using HTTPS, hides the
  if (location.protocol != 'https:') {
    myElement.parentNode.removeChild(myElement);
  }
  const icon = {
    path: MARKER_SVG,
    fillColor: '#DC4405',
    fillOpacity: 1,
    strokeWeight: 0.5,
    scale: 0.4,
    anchor: new google.maps.Point(50, 50),
    zIndex: 301,
  };
  let my_pos = new google.maps.Marker({
    position: null,
    map: map,
    title: 'You are here.',
    icon: icon,
    animation: google.maps.Animation.DROP,
  });
  myElement.addEventListener('click', function () {
    // Uses HTML5 Geolocation API to get your position
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let latlng = new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );

        my_pos.setPosition(latlng);
        map.panTo(latlng);
      });

      // For tablets and phones, hide the sidebar after clicking to find your position
      if (window.innerWidth < 767) {
        hideSidebar();
        //toggle between close and expand icons
        $('#search-toggle > i').toggleClass('icon-remove icon-search');
      }
    } else {
      this.insertAdjacentHTML(
        'afterend',
        '<p>Your browser does not support this feature</p>'
      );
    }
  });
}
