// Search inside an array function to be used to sort synonyms and more
import {
  active_polygon,
  clear_polygon,
  build_polygon_array,
} from './map_helpers';
import { setMarker } from './layers_markers';
import { get_building } from './buildings';
import { LOC_ENDPOINT } from './globals';
import { update_url } from './url_manipulation';
import { hideSidebar } from '../off_canvas';

export function keywordSearch(searchString, keywords) {
  // Lowercase the search words & break up the search into separate words
  var searchWords = searchString.toLowerCase().split(' ');
  // Count number of search words
  var numOfSearchWords = searchWords.length;
  // Count the number of keywords
  var numOfKeywords = keywords.length;
  // Will contain the keywords that matched the search words
  var matches = [];

  // For each search word look up the keywords array to see if the search word
  // partially matches the keyword
  for (var i = 0; i < numOfSearchWords; i++) {
    // For each keyword
    for (var j = 0; j < numOfKeywords; j++) {
      // Check search word is part of a keyword
      if (keywords[j].indexOf(searchWords[i]) != -1) {
        // Found match, store match, then look for next search word
        matches.push(keywords[j]);
        break;
      }
    }
  }

  // Count the number of matches, and if it equals the number of search words
  // then the search words match the keywords
  if (matches.length == numOfSearchWords) {
    return true;
  }

  return false;
}

export function campus_map_autocomplete(map, search_json, loc_apikey) {
  // @TODO we need to refactor this so it's called only once
  $.getJSON(search_json, function (json) {
    var temp = [];
    for (var key in json) {
      if (json.hasOwnProperty(key)) {
        var item = json[key];
        temp.push({
          label: item.label,
          id: item.id,
          abbr: item.abbr,
          lat: parseFloat(item.lat),
          lng: parseFloat(item.lng),
          hasPolly: item.hasPolly,
          keywords: item.keywords,
          //center: item.center
        });
      }
    }

    $('#mapsearch').autocomplete({
      delay: 500,
      minLength: 2,
      // source: temp, //this works watching labels
      source: function (request, response) {
        var matched = [];
        var numOfLinks = temp.length;

        // Get entered search terms (request.term) from user and search
        // through all links keywords
        for (var k = 0; k < numOfLinks; k++) {
          // If it matches, push the object into a new array
          if (keywordSearch(request.term, temp[k].keywords)) {
            matched.push(temp[k]);
          }
        }

        // Display the filtered results
        response(matched);
      },
      select: function (event, ui) {
        $('#mapsearch').val(ui.item.label);
        const latlong = { lat: ui.item.lat, lng: ui.item.lng };
        const polygon = ui.item.hasPolly;
        let marker = window.marker;

        if (polygon) {
          if (marker) {
            // clear marker if we are going to draw a polygon
            marker.setMap(null);
            window.marker = null;
          }
          $.getJSON(
            LOC_ENDPOINT + ui.item.id + '?apikey=' + loc_apikey,
            function (my_response) {
              clear_polygon();
              let my_paths = build_polygon_array(
                my_response.data.attributes.geometry.coordinates,
                my_response.data.attributes.geometry.type
              );
              active_polygon(latlong, my_paths, map, loc_apikey, ui.item.id);
              map.panTo(latlong);
            }
          );
        } else {
          clear_polygon();
          setMarker(map, latlong);
          // in some cases marker was still null here
          marker = window.marker;

          marker.addListener('click', function () {
            get_building(loc_apikey, ui.item.id);
            // @TODO if statements for other layer types? Maybe just info windows
          });
        }

        // Hide the building data card if it is visible
        var bd = document.querySelector('#building_data');
        if (bd.getAttribute('aria-hidden') == 'false') {
          bd.setAttribute('aria-hidden', true);
        }
        //update URLs with building or ids
        if (ui.item.abbr) {
          update_url('building', ui.item.abbr);
        } else {
          update_url('id', ui.item.id);
        }

        // Hide the virtual keyboard on iOS and Android devices
        $('#mapsearch').blur();

        // Hide the sidebar only in mobile devices
        if (window.innerWidth < 767) {
          // closes sidebar
          hideSidebar();

          //toggle between close and expand icons
          $('#search-toggle > i').toggleClass('icon-remove icon-search');
        }

        return false;
      },
      focus: function (event, ui) {
        // When the item is selected, put the label text into the search box
        $('#mapsearch').val(ui.item.label);
        return false;
      },
    });
  });
}
