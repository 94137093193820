/*global google*/
import { get_building } from './buildings';
import { MARKER_SVG } from './globals';

/**
 * Function to set marker on map to be used throughout
 * load_data is an optional parameter, if it is set to true it loads the
 * building too.
 * @param {google map} map
 * @param {lat long} location
 */
export function setMarker(map, location) {
  let icon = {
    path: MARKER_SVG,
    fillColor: '#DC4405',
    fillOpacity: 1,
    strokeWeight: 0.5,
    scale: 0.4,
    anchor: new google.maps.Point(50, 50),
    zIndex: 301,
  };
  // window.marker gets the marker variable from the global scope
  // If marker is null, it creates a new marker
  if (window.marker == null) {
    window.marker = new google.maps.Marker({
      map: map,
      position: location,
      icon: icon,
    });
  }
  // If marker exists, it just moves the marker to a new position
  else {
    window.marker.setPosition(location);
  }
  map.panTo(location);
}

// Will contain all info windows for easy closing
const infoWindows = [];
const markers = [];

// Creates a hidden layer with the markers of the locations
export function setHiddenLayer(
  map,
  location,
  MARKERTYPE,
  // marker_var,
  loc_id,
  loc_apikey,
  loc_name
) {
  let icon = {
    path: MARKER_SVG,
    fillColor: MARKERTYPE.color,
    fillOpacity: 1,
    strokeWeight: 0.5,
    scale: 0.35,
    anchor: new google.maps.Point(50, 85),
  };

  let marker_var = new google.maps.Marker({
    map: map,
    position: location,
    icon: icon,
    type: MARKERTYPE.name,
    title: loc_name,
  });
  marker_var.setVisible(false);
  markers.push(marker_var);

  if (marker_var) {
    const infowindow = new google.maps.InfoWindow({
      content: loc_name,
    });

    infoWindows.push(infowindow);

    // Defines click behaviors for the marker types
    marker_var.addListener('click', function () {
      if (MARKERTYPE.name == 'dining') {
        get_building(loc_apikey, loc_id);
      } else {
        // Hide the Large Building Panel
        const bd = document.querySelector('#building_data');
        bd.setAttribute('aria-hidden', true);

        // Close info windows
        for (var i = 0; i < infoWindows.length; i++) {
          infoWindows[i].close();
        }
        infowindow.open(map, marker_var);
      }
    });
  }

  let cb = document.querySelector(MARKERTYPE.checkbox_id);
  // Show/hide layers based on condition of checkbox
  cb.addEventListener('change', () => {
    marker_var.setVisible(cb.checked ? true : false);
    // Find all
    // if (cb.id === 'covidADA') {
    //   const covidMarkers = markers.filter((m) => m.type === 'covid');
    //   covidMarkers.forEach((cm) => {
    //     cm.setVisible(!cb.checked);
    //   });
    // }
    //
    // if (cb.id === 'covid' && !cb.checked) {
    //   const adaCB = document.getElementById('covidADA');
    //   adaCB.checked = false;
    //   const covidMarkers = markers.filter((m) => m.type === 'covidADA');
    //   covidMarkers.forEach((cm) => {
    //     cm.setVisible(false);
    //   });
    // }
  });

  // IF swipe function enabled (Doug Fir, jquery mobile subset)
  if (
    typeof $.fn.swiperight === 'function' &&
    typeof $.fn.swipeleft === 'function'
  ) {
    // Attach swipe event to the labels/pins
    $('#' + cb.id + ' + label').swipeleft(() => {
      marker_var.setVisible(cb.checked ? true : false);
      document.getElementById(cb.id).checked = false;
    });
    //Attach swipe event to the label to trigger pins
    $('#' + cb.id + ' + label').swiperight(() => {
      marker_var.setVisible(cb.checked ? true : false);
      document.getElementById(cb.id).checked = true;
    });
  }
}
