import { setHiddenLayer } from './layers_markers';
import { BLUELIGHTS } from './globals';

/**
 * This will parse the Security lights cache file and add them to the map.
 */
export function blueLightsLayer(map, security_path, loc_apikey) {
  $.getJSON(security_path, function (response) {
    // Check to make sure there is good data.
    if (response.type === 'blueLightPhones') {
      // Loop over each point and create an object to add to the map.
      for (let i = 0; i < response.attributes.blueLightPhones.length; i++) {
        // Creating lat/long object.
        var light_point = {
          lat: parseFloat(response.attributes.blueLightPhones[i].latitude),
          lng: parseFloat(response.attributes.blueLightPhones[i].longitude),
        };
        var alt_text = 'Emergency Blue Light and telephone';
        // Call our function to add it to the map.
        setHiddenLayer(
          map,
          light_point,
          BLUELIGHTS,
          null,
          loc_apikey,
          alt_text
        );
      }
    }
  });
}
