/**
 * Created by cordobam on 6/7/17.
 * Adds accessibility and functionality to the off canvas navigation
 * Toggles and expanded class on the sidebar to trigger a CSS transition
 */

/*
 * Lots of functionality from the panels that come in and out of the map
 * Right sidebar (layers, search)
 * Left sidebar (building data)
 * Button UI for toggling all of these
 */
export function offCanvas() {
  // Selectors to cache (used more than once)
  const map_layer = $('#map_layers');
  const map_sidebar = $('#map_sidebar');
  const mapsearch = $('#mapsearch');
  const search_toggle = $('#search-toggle');
  const inline_search = $('.js-inline-search');
  const building_close = $('#building_close');

  // Desktop slide in data
  $('#map_toggle').on('click', (e) => {
    e.preventDefault();
    $('.ui-menu-item').hide();

    // aria accessibility for the link that hides/shows the map layers
    $(this).attr('aria-expanded', function (index, attr) {
      return attr == 'true' ? 'false' : 'true';
    });

    map_layer.attr(
      'aria-hidden',
      map_layer.attr('aria-hidden') == 'false' ? true : false
    );

    //toggles expanded class which triggers a CSS transition
    map_sidebar.toggleClass('expanded');

    //toggle between close and expand icons
    $('.js-sidebar-toggle').toggleClass('icon-remove icon-chevron-left');
  });

  // Enable hiding the sidebar by swiping
  if (typeof $.fn.swiperight === 'function') {
    // IDS of the radio buttons for the layers in page.tpl.php
    map_sidebar.swiperight((e) => {
      // Exclude the event for the labels that turn on layers
      if (e.target.tagName !== 'LABEL') {
        toggleSidebar();
      }
    });
  }

  // Toggles search area
  search_toggle.on('click', (f) => {
    f.preventDefault();
    toggleSidebar();
  });

  // Parking swipe events in parking.js file

  // Covid Entrance and Exit controls
  // expandLabel('#covid', '.covid');

  // Helper function to use on swipe and on click that triggers all the changes
  function toggleSidebar() {
    // Hide or show the "Explore" text on mobile.
    if (window.innerWidth < 767) {
      search_toggle.toggleClass('explore');
    }
    map_sidebar.toggleClass('expanded');

    //toggle between close and expand icons
    $('.js-search-toggle').toggleClass('icon-remove icon-search');

    //aria accessibility for the layer data
    map_layer.attr(
      'aria-hidden',
      map_layer.attr('aria-hidden') == 'false' ? true : false
    );
  }

  // Search auto complete change the icons
  mapsearch.keyup(function () {
    if ($(this).val().length > 0) {
      inline_search.addClass('icon-remove').removeClass('icon-search');
    } else {
      inline_search.addClass('icon-search').removeClass('icon-remove');
    }
  });

  // Clears the value on click..
  $('#map_search_btn').on('click', (g) => {
    g.preventDefault();

    mapsearch.val('');
    inline_search.toggleClass('icon-remove icon-search');
  });

  // Desktop sizes, we add classes to open the sidebar by default
  if (window.innerWidth > 767) {
    map_sidebar.addClass('expanded');
    search_toggle.removeClass('explore').attr('aria-hidden', 'true');
    map_layer.attr('aria-hidden', 'false'); //shows map layer for desktop
  }

  // This the close functionality. Open functionality with google maps code
  $(building_close).on('click', (e) => {
    e.preventDefault();

    //aria accessibility for the layer data
    $('#building_data').attr('aria-hidden', true);
  });
  if (typeof $.fn.swiperight === 'function') {
    building_close.swiperight(() => {
      $('#building_data').attr('aria-hidden', true);
    });
  }
}

/*
 * Accessible way of hiding the sidebar, triggered on various files.
 */
export function hideSidebar() {
  $('#map_sidebar').removeClass('expanded');
  $('#map_layers').attr('aria-hidden', true);

  if (window.innerWidth < 767) {
    $('#search-toggle').toggleClass('explore');
  }
}

/**
 * Utility for click and swipe events when we expand more data
 * @param {*} label the id of the label
 * @param {*} target the DOM element to expand
 */
export function expandLabel(label, target) {
  const el = document.querySelector(label);

  el.addEventListener('click', function () {
    if (el.checked) {
      $(target).addClass('active');
    } else {
      $(target).removeClass('active');
    }
  });

  // Swipe events for checkbox slider
  if (
    typeof $.fn.swiperight === 'function' &&
    typeof $.fn.swipeleft === 'function'
  ) {
    const item = $(label + ' + label');
    // Attach swipe event to the labels/pins
    item.swipeleft(() => {
      document.getElementById(el.id).checked = false;
      $(target).removeClass('active');
    });

    // Attach swipe event to the label to expand item
    item.swiperight(() => {
      document.getElementById(el.id).checked = true;
      $(target).addClass('active');
    });
  }
}
