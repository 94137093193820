import { LOC_ENDPOINT } from './globals';
import { active_polygon, build_polygon_array } from './map_helpers';
import { setMarker } from './layers_markers';

/*
 * Cross domain request to Locations API
 * Brings the data directly from the API to load on the page
 * Loads data as an HTML card
 * if not_open is passed, then we do not automatically load the building.
 */
export function get_building(locations_apikey, building_id, map, not_open) {
  // Test end point, for new features, development and such
  const bd = document.querySelector('#building_data');

  // Do not auto display building data
  if (!not_open) {
    bd.setAttribute('aria-hidden', false);
  }
  //clear html
  $('#building_details').html('');

  var id_key = building_id + '?apikey=' + locations_apikey;

  /* Create the building layer HTML from API */
  $.getJSON(LOC_ENDPOINT + id_key, function (building) {
    let html = '';
    var b = building.data.attributes;
    var abbr = '';
    if (b.abbreviation) {
      abbr = '(' + b.abbreviation + ')';
    }

    html += '<div class="building">';
    html += '<h2>' + b.name + ' ' + abbr + '</h2>';
    if (b.images.length > 0) {
      html += '<img src="' + b.images[0] + '"/>';
    }
    if (b.address && b.address.length > 4) {
      html += '<p>' + b.address + '</p>';
    }
    if (b.description && b.description.length > 4) {
      html += '<p>' + b.description + '</p>';
    }
    //html += '<p>'+b.openHours+'</p>'; // this array is a mess
    if (b.giRestroomCount > 0) {
      let room = '';
      if (b.giRestroomCount > 1) {
        room = 's'; //plural
      }
      html +=
        '<p>' +
        b.giRestroomCount +
        ' gender inclusive restroom' +
        room +
        ' in room' +
        room +
        ': ' +
        b.giRestroomLocations +
        '</p>';
    }
    html += '</div>';

    $('#building_details').html(html);

    /* If we pass map to this function, it will automatically open the building details
     * Also senters the map, colors the polygon (if available), or drops a pin
     */
    if (map) {
      let latlong = {
        lat: parseFloat(b.latitude),
        lng: parseFloat(b.longitude),
      };

      // If this building is a polygon, highlight it
      if (b.geometry) {
        let my_paths = build_polygon_array(
          b.geometry.coordinates,
          b.geometry.type
        );
        active_polygon(latlong, my_paths, map, locations_apikey, building_id);
      } else {
        // set a marker if it doesn't have polygon information
        setMarker(map, latlong);
      }
      map.panTo(latlong);
    }
  });
}
