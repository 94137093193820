import { offCanvas } from './off_canvas';
import { campus_map_autocomplete } from './map/search_autocomplete';
import {
  featureOpts,
  styledMapOptions,
  MY_MAPTYPE_ID,
  mapOptions,
} from './map/map_helpers';
import { blueLightsLayer } from './map/blue_light_layer';
import { buildingPolygonsLayer } from './map/building_polygon_layer';
import { geolocateMe } from './map/geolocation';
import { load_url } from './map/url_manipulation';
import { parkingListener } from './map/parking';

var selected_polygon = null;

(function ($) {
  // To be able to use Jquery on the various imported files above
  window.$ = $;
  window.selected_polygon = selected_polygon;

  Drupal.campusMap = function () {
    const locations_apikey = Drupal.settings.campus_map.locations_apikey;

    const abbr_id_endpoint = Drupal.settings.campus_map.abbr_id_endpoint;

    mapOptions['center'] = new google.maps.LatLng(
      Drupal.settings.campus_map.map_center_point[0],
      Drupal.settings.campus_map.map_center_point[1]
    );

    var map = new google.maps.Map(
      document.getElementById('campus_map'),
      mapOptions
    );

    var customMapType = new google.maps.StyledMapType(
      featureOpts,
      styledMapOptions
    );

    map.mapTypes.set(MY_MAPTYPE_ID, customMapType);

    campus_map_autocomplete(
      map,
      Drupal.settings.campus_map.search_path,
      locations_apikey
    );

    // Polygon building
    buildingPolygonsLayer(
      map,
      Drupal.settings.campus_map.building_json,
      locations_apikey
    );

    // Blue Light Security Layer
    blueLightsLayer(map, Drupal.settings.campus_map.security_path);

    // Parking Layers
    parkingListener(map, locations_apikey);

    geolocateMe(map);

    load_url(locations_apikey, map, abbr_id_endpoint);
  }; // Close Drupal.campusmap

  Drupal.behaviors.campusMap = {
    attach: function (context) {
      // Run the off canvas UI
      offCanvas();

      // Initialize Campus map
      $('#campus_map', context).once('campusMap', function () {
        Drupal.campusMap();
      });
    },
  };
})(jQuery);
