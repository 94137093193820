/*global google*/
import { LOC_ENDPOINT } from './globals';

const parkingStyle = {
  fillColor: '#e77a4e',
  strokeWeight: 0,
  fillOpacity: 1,
};

let parkingInfoWindow = '';

export function parkingListener(map, loc_apikey) {
  const parking = document.querySelector('#parking');
  const select = document.querySelector('#select-parking');
  let myParkingLayer = new google.maps.Data();
  myParkingLayer.setStyle(parkingStyle);

  // Sets general parking to hide R1 and R2 lots among with others
  let api_urls = selectedParkingUrl('all', loc_apikey);
  $.getJSON(api_urls, function (data) {
    myParkingLayer.addGeoJson(data);

    myParkingLayer.addListener('click', function (event) {
      pInfoWindow(event, map);
    });
  });

  parking.addEventListener('click', function () {
    if (parking.checked) {
      $('.parking').addClass('active');
      myParkingLayer.setMap(map);
    } else {
      myParkingLayer.setMap(null);
      $('.parking').removeClass('active');
    }
  });

  // Swipe events for Parking checkbox slider
  if (
    typeof $.fn.swiperight === 'function' &&
    typeof $.fn.swipeleft === 'function'
  ) {
    // Attach swipe event to the labels/pins
    $('#parking + label').swipeleft(() => {
      document.getElementById(parking.id).checked = false;
      myParkingLayer.setMap(null);
      $('.parking').removeClass('active');
    });

    //Attach swipe event to the label to trigger pins
    $('#parking + label').swiperight(() => {
      document.getElementById(parking.id).checked = true;
      myParkingLayer.setMap(map);
      $('.parking').addClass('active');
    });
  }

  select.addEventListener('change', function (e) {
    // Reset parking layer with specific selection
    myParkingLayer.setMap(null);

    // Set the custom location
    myParkingLayer = new google.maps.Data({ map: map });
    myParkingLayer.setStyle(parkingStyle);

    let api_url = selectedParkingUrl(e.target.value, loc_apikey);

    $.getJSON(api_url, function (response) {
      myParkingLayer.addGeoJson(response);

      myParkingLayer.addListener('click', function (event) {
        pInfoWindow(event, map);
      });
    });
  });
}

/* Creates infowindow for Google Maps with Parking data */
function pInfoWindow(event, map) {
  if (parkingInfoWindow) {
    parkingInfoWindow.close();
  }
  let parkingAttributes = event.feature.getProperty('attributes');

  var contentString = `<div class="parking-infowindow">
    <p>Parking Zone: <strong>${parkingAttributes.parkingZoneGroup}</strong><br>
       ${parkingAttributes.name}
     </p> 
     <ul>
      <li>ADA parking spaces: ${parkingAttributes.adaParkingSpaceCount}</li>
      <li>Motorcycle parking spaces: ${parkingAttributes.motorcycleParkingSpaceCount}</li>
      <li>Electric vehicle parking spaces: ${parkingAttributes.evParkingSpaceCount}</li>
   </ul></div>
  `;

  parkingInfoWindow = new google.maps.InfoWindow({
    content: contentString,
  });

  parkingInfoWindow.setPosition(event.latLng);
  parkingInfoWindow.open(map);

  // Centers parking lot info window
  if (window.innerWidth > 767) {
    map.panTo(event.latLng);
  }
}

/* Creates the API url to request particular parking zones */
function selectedParkingUrl(zone, loc_apikey) {
  if (zone === 'ADA') {
    return `${LOC_ENDPOINT}?type=parking&adaParkingSpaceCount=1&page[size]=10000&apikey=${loc_apikey}&geojson=true`;
  }

  // Other parking zones available if you own a particular pass
  let B = [['C']];
  let A = [['B1', 'B2', 'B3'], ...B];
  let ALL = [['A1', 'A2', 'A3'], ...A];

  // Data only has the R zones as of September 2019. We can strip this zone array later.
  let RES = [['R', 'R1', 'R2']];

  let zones = [];

  // Small helper function to help with redundancies
  // Returns the array of parking zones where a particular person can park
  function zoneArray(other_zones = '') {
    if (other_zones) {
      other_zones.push(zone);
      zones = other_zones.slice(0);
    } else {
      zones.push(zone);
    }

    return zones;
  }

  switch (zone) {
    case 'A1': {
      zoneArray(A);
      break;
    }
    case 'A2': {
      zoneArray(A);
      break;
    }
    case 'A3': {
      zoneArray(A);
      break;
    }
    case 'B1': {
      zoneArray(B);
      break;
    }
    case 'B2': {
      zoneArray(B);
      break;
    }
    case 'B3': {
      zoneArray(B);
      break;
    }
    case 'C': {
      zoneArray();
      break;
    }
    case 'R': {
      zoneArray(RES);
      break;
    }
    default: {
      zoneArray(ALL);
      break;
    }
  }

  // builds array copy with new data...
  let parking_zones = zones.map((x) => '&parkingZoneGroup=' + x);

  let api_url =
    LOC_ENDPOINT +
    '?type=parking' +
    parking_zones +
    '&page[size]=10000' +
    '&apikey=' +
    loc_apikey +
    '&geojson=true';

  return api_url;
}
