import { get_building } from './buildings';

// Helper function to get URL parameters
export function getAllUrlParams(url) {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  let obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // in case params look like: list[]=thing1&list[]=thing2
      var paramNum = undefined;
      var paramName = a[0].replace(/\[\d*\]/, function (v) {
        paramNum = v.slice(1, -1);
        return '';
      });

      // set parameter value (use 'true' if empty)
      var paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // if parameter name already exists
      if (obj[paramName]) {
        // convert value to array (if still string)
        if (typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]];
        }
        // if no array index number specified...
        if (typeof paramNum === 'undefined') {
          // put the value on the end of the array
          obj[paramName].push(paramValue);
        }
        // if array index number specified...
        else {
          // put the value at that index number
          obj[paramName][paramNum] = paramValue;
        }
      }
      // if param name doesn't exist yet, set it
      else {
        obj[paramName] = paramValue;
      }
    }
  }

  return obj;
}

// In progress, maybe for a later release
export function update_url(type, value) {
  var url_params = '?' + type + '=' + value;
  window.history.pushState(null, null, url_params);
}

/* Url manipulation for linking with IDs as well as Abbreviations */
export function load_url(loc_apikey, map, abbr_endpoint) {
  let open_building = true;

  //  Do not open building for small screens and devices
  if (window.innerWidth > 767) {
    open_building = false;
  }

  // If ID is present we load the building from the api
  if (getAllUrlParams().id) {
    get_building(loc_apikey, getAllUrlParams().id, map, open_building);
  }

  /* Fetch the real ID so we can do an API call
   * If the 'building' parameter is in the url
   * This queries against a local (drupal site) end point pairing of abbr and id
   */
  if (getAllUrlParams().building) {
    const abbr = getAllUrlParams().building;
    $.getJSON(abbr_endpoint + '?abbr=' + abbr, function (my_response) {
      if (my_response[0].id) {
        get_building(loc_apikey, my_response[0].id, map, open_building);
      }
    });
  }
}
