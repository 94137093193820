/* global google */
/* global selected_polygon */
/* Provides common options for polygon drawings */
import { get_building } from './buildings';

export function create_polygon(
  response_data,
  uluru,
  path,
  fill_color = '#666666'
) {
  return new google.maps.Polygon({
    paths: path,
    strokeColor: '#000000',
    strokeWeight: 0,
    fillColor: fill_color, //#8e9089',
    fillOpacity: 1,
    clickable: true,
    name: response_data.attributes.abbreviation,
    id: response_data.id,
    center: uluru,
  });
}

/*  Map features that Google Draws from their data
 *  These are what we customize to show behind our polygons (like street names)
 *  We are showing the standard Google Maps colors with a handful of changes
 */
export const featureOpts = [
  {
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
      {
        weight: 2,
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];
// Name of Custom Style
export const styledMapOptions = {
  name: 'Map',
};

// var infoWindow = null;
export const MY_MAPTYPE_ID = 'osu_style';

/**
 * Initial Map Control options
 * Things like initial location, and map controls, zoom level and more
 */
export const mapOptions = {
  zoom: 16,
  // Moved to main file.
  //center: new google.maps.LatLng(44.56507, -123.2761),
  mapTypeId: MY_MAPTYPE_ID,
  mapTypeControl: true,
  mapTypeControlOptions: {
    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    mapTypeIds: [MY_MAPTYPE_ID, google.maps.MapTypeId.SATELLITE],
  },
  disableDefaultUI: false,
  scaleControl: true,
  streetViewControl: false,
  zoomControl: true,
  tilt: 0, // disables 3D renderings in satellite mode, do not line up with 2D shapes
  fullscreenControl: false,
  zoomControlOptions: {
    position: google.maps.ControlPosition.TOP_LEFT,
    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
  },
};

//export var selected_polygon;

/**
 * Sets an Active Polygon Layer
 * Only 1 at a time, or it destroys the polygons
 */
export function clear_polygon() {
  if (selected_polygon) {
    selected_polygon.setMap(null);
    selected_polygon = '';
  }
}

export function active_polygon(
  center,
  polygon_latlng,
  map,
  loc_apikey,
  building_id
) {
  clear_polygon();

  selected_polygon = new google.maps.Polygon({
    map: map,
    paths: polygon_latlng,
    fillColor: '#DC4405',
    center: center,
    strokeWeight: 0,
    fillOpacity: 1,
    clickable: true,
    zIndex: 400,
  });

  selected_polygon.setMap(map);
  selected_polygon.addListener('click', function () {
    get_building(loc_apikey, building_id);
  });
}

export function build_polygon_array(polygon, type) {
  var objects = [];
  switch (type) {
    case 'Polygon':
      for (let i = 0; i < polygon.length; i++) {
        let localPoly = [];
        for (let j = 0; j < polygon[i].length; j++) {
          localPoly.push(
            new google.maps.LatLng(polygon[i][j][1], polygon[i][j][0])
          );
        }
        objects.push(localPoly);
      }
      break;
    case 'MultiPolygon':
      for (let i = 0; i < polygon.length; i++) {
        let localPoly = [];
        for (let j = 0; j < polygon[i][0].length; j++) {
          localPoly.push(
            new google.maps.LatLng(polygon[i][0][j][1], polygon[i][0][j][0])
          );
        }
        objects.push(localPoly);
      }
      break;
  }
  return objects;
}
