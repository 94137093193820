export const BLUELIGHTS = {
  checkbox_id: '#emergency',
  color: '#00859B',
  name: 'blue_lights',
};

export const GI_RESTROOM = {
  checkbox_id: '#gib',
  color: '#C4D6A4',
  name: 'gi_restrooms',
};

// export const COVID_ENTRANCE = {
//   checkbox_id: '#covid',
//   color: '#C6DAE7',
//   name: 'covid',
// };
//
// export const COVID_ENTRANCE_ADA = {
//   ...COVID_ENTRANCE,
//   checkbox_id: '#covidADA',
//   name: 'covidADA',
// };
//
// export const COVID_EXIT = {
//   ...COVID_ENTRANCE,
//   color: '#C72127',
// };
//
// export const COVID_EXIT_ADA = {
//   ...COVID_ENTRANCE_ADA,
//   color: '#C72127',
// };

export const DINING = {
  checkbox_id: '#dining',
  color: '#ffb500',
  name: 'dining',
};

// export const LOC_ENDPOINT = 'https://oregonstateuniversity-dev.apigee.net/v1/locations/';
// export const LOC_ENDPOINT = 'https://oregonstateuniversity-test.apigee.net/v1/locations/';
export const LOC_ENDPOINT = 'https://api.oregonstate.edu/v1/locations/';

export const MARKER_SVG =
  'M49.8,11.1C33.9,11.1,21,24,21,39.9c0,4.1,0.8,8.1,2.5,11.8c7.2,15.7,21,32.4,25.1,37.1 c0.3,0.4,0.7,0.6,1.2,0.6c0.5,0,0.9-0.2,1.2-0.6C55,84,68.8,67.4,76,51.6c1.7-3.7,2.5-7.6,2.5-11.8C78.6,24,65.6,11.1,49.8,11.1 L49.8,11.1z M49.8,54.8c-8.2,0-14.9-6.7-14.9-14.9c0-8.2,6.7-14.9,14.9-14.9c8.2,0,15,6.7,15,14.9C64.7,48.1,58,54.8,49.8,54.8 L49.8,54.8z M49.8,54.8';
