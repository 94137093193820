/*global google*/
/*global MapLabel*/
import { setHiddenLayer } from './layers_markers';
import {
  create_polygon,
  active_polygon,
  build_polygon_array,
} from './map_helpers';
import { get_building } from './buildings';
// import { covidExitEntrance } from './covid_doors';
import { GI_RESTROOM, DINING } from './globals';
import { update_url } from './url_manipulation';

/* Parse the building data layer, polygon, multipolygons and more */
export function buildingPolygonsLayer(map, building_json, locations_apikey) {
  // Simple function to build the click event for the different polygons
  function poly_click(polygon) {
    polygon.setMap(map);
    polygon.addListener('click', function () {
      //AJAX request to the locations API for specific building data
      get_building(locations_apikey, this.id);
      // highlight polygon
      active_polygon(
        this.center,
        this.getPaths(),
        map,
        locations_apikey,
        this.id
      );

      //update url
      if (this.name) {
        update_url('building', this.name);
      } else {
        update_url('id', this.id);
      }
    });
  }

  function updateColor(polygon, mapType) {
    if (mapType === 'satellite') {
      polygon.setOptions({
        fillOpacity: 0.4,
        strokeWeight: 2,
        strokeOpacity: 1.0,
        strokeColor: '#DC4405',
      });
    } else {
      polygon.setOptions({ fillOpacity: 1.0, strokeOpacity: 0.0 });
    }
  }

  $.getJSON(building_json, function (response) {
    for (let i = 0; i < response.data.length; i++) {
      // Markers are better
      var uluru = {
        lat: parseFloat(response.data[i].attributes.latitude),
        lng: parseFloat(response.data[i].attributes.longitude),
      };

      // Easy variable to call the current building/element/location id
      var loc_id = response.data[i].id;

      // Gender Inclusive bathroom layers/markers setup
      if (
        typeof (response.data[i].attributes.giRestroomCount !== 'undefined') &&
        parseInt(response.data[i].attributes.giRestroomCount) > 0
      ) {
        var restroom_numbers = response.data[i].attributes.giRestroomLocations;
        var alt_text =
          response.data[i].attributes.giRestroomCount +
          ' Gender Inclusive Restroom(s): ' +
          restroom_numbers;
        setHiddenLayer(
          map,
          uluru,
          GI_RESTROOM,
          null,
          locations_apikey,
          alt_text
        );
      }

      // Covid entries and exits are currently under adaEntries array
      // if (response.data[i].attributes.adaEntries.length > 0) {
      //   covidExitEntrance(
      //     map,
      //     response.data[i].attributes.adaEntries,
      //     locations_apikey
      //   );
      // }

      if (response.data[i].attributes.type === 'dining') {
        setHiddenLayer(
          map,
          uluru,
          DINING,
          loc_id,
          locations_apikey,
          response.data[i].attributes.name
        );
      }

      if (response.data[i].attributes.type === 'parking') {
        continue;
      }

      // Only build if we have geometry data
      if (response.data[i].attributes.geometry) {
        let path = build_polygon_array(
          response.data[i].attributes.geometry.coordinates,
          response.data[i].attributes.geometry.type
        );
        let building = create_polygon(response.data[i], uluru, path);

        google.maps.event.addListener(map, 'maptypeid_changed', function () {
          updateColor(building, map.getMapTypeId());
        });

        poly_click(building);
      }

      if (
        jQuery.isNumeric(uluru.lat) &&
        jQuery.isNumeric(uluru.lng) &&
        response.data[i].attributes.type == 'building'
      ) {
        let polyLabel = new MapLabel({
          text: response.data[i].attributes.name,
          position: new google.maps.LatLng(uluru),
          map: map,
          minZoom: 18,
          zIndex: 401,
          fontFamily: 'Open sans',
          strokeWeight: 8,
        });
        let polyAbbreviation = new MapLabel({
          text: response.data[i].attributes.abbreviation,
          position: new google.maps.LatLng(uluru),
          map: map,
          minZoom: 16,
          maxZoom: 17,
          zIndex: 401,
          fontFamily: 'Open sans',
          strokeWeight: 6,
        });
        polyLabel.set('position', new google.maps.LatLng(uluru));
        polyAbbreviation.set('position', new google.maps.LatLng(uluru));
      }
    }
  });
}
